* {
   box-sizing: border-box;
   padding: 0; margin: 0;
   line-height: 1;
   -ms-overflow-style: none; /** IE, Edge */
   scrollbar-width: none; /** firefox */
}

*::-webkit-scrollbar {
   display: none; /** chrome, safari, opera */
}

html, body {
   width: 100%; height: 100%;
   min-width: 280px;
   font: 500 10px/1 'Noto Sans KR', sans-serif;
   overflow: hidden;
}

a {
   color: inherit;
   text-decoration: none;
}

button {
   border: none; outline: none;
   background: none;
   cursor: pointer;
}

input, textarea {
   border: none; outline: none; resize: none;
   background: none;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}

input::-ms-clear {
   display: none;
}

ul {
   list-style: none;
}

#root {
   display: flex; flex-direction: column;
   width: 100%; height: 100%;
   min-width: 100%; min-height: 100%;
}
